<template>
  <div>
    <Pane />

    <a-form :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }" :colon="false">
      <div class="container">
        <div class="header">
          <img src="../../assets/contract-register/icon-info.png" alt="" />
          <span>基本信息</span>
        </div>
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="经办部门">
              <div class="value">
                {{ detail.operationDeptName }}
              </div>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="经办人">
              <div class="value">
                {{ selectedUser.name }}
              </div>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="合同编号">
              <div class="value">
                {{ selectedContract.code }}
              </div>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="合同名称">
              <div class="value">
                {{ selectedContract.name }}
              </div>
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="工程概况" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
              <div class="value">
                {{ detail.scale }}
              </div>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="分包名称">
              <div class="value">
                {{ detail.name }}
              </div>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="分包方">
              <div class="value">
                {{ selectedCompany.name }}
              </div>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="合同金额(万元)">
              <div class="value">
                <Money :money="detail.actualAmount" />
              </div>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="用章名称">
              <div class="value">
                <span class="left" v-if="typeof detail.sealType === 'string'">
                  <span class="left" v-for="(item, index) in detail.sealType.split(',')" :key="item">
                    <DataDictFinder dictType="sealType" :dictValue="item" />
                    <span v-if="index !== detail.sealType.split(',').length - 1">/</span>
                  </span>
                </span>
              </div>
            </a-form-item>
          </a-col>
        </a-row>
      </div>

      <div class="container">
        <div class="header">
          <img src="../../assets/contract-register/icon-project.png" alt="" />
          <span>分包信息</span>
        </div>
        <a-row>
          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="所属区域" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
              <div class="left value">
                <span class="left" v-for="(item, index) in area" :key="index">
                  {{ item }}
                  <span v-if="index !== area.length - 1">/</span>
                </span>
              </div>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="项目地点">
              <div class="left value">
                <span class="left" v-for="(item, index) in location" :key="index">
                  {{ item }}
                  <span v-if="index !== location.length - 1">/</span>
                </span>
              </div>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="国外">
              <div class="value">
                {{ detail.isAbroad }}
              </div>
              <div class="tip">
                <a-icon type="question-circle" />
                项目地点在国外时填写
              </div>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="分包属性">
              <div class="value">
                <span v-if="detail.isneeded === 1"> 设计类-必要分包</span>
                <span v-if="detail.isneeded === 0"> 设计类-非必要分包</span>
                <span v-if="detail.isneeded === 2"> 总承包类分包</span>
              </div>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="分包类别">
              <div class="value">
                {{ getType(detail.type) }}
              </div>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24" v-if="detail.isneeded === 0 && detail.type === 'JS3'">
            <a-form-item label="专业类型">
              <div class="value">
                <DataDictFinder dictType="majorTypes" :dictValue="detail.major" />
              </div>
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="支付方式" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
              <div class="value">
                {{ detail.paymentMode }}
              </div>
            </a-form-item>
          </a-col>
        </a-row>
      </div>

      <div class="container">
        <div class="header">
          <img src="../../assets/contract-register/icon-scale.png" alt="" />
          <span>项目分包明细(含预测)</span>
        </div>

        <Padding size="large" />

        <a-form-item label="项目分包明细(含预测)" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
          <div style="margin-top: 4px">
            <SubpackageList :list="subpackageList" :visible="addSubpackageVisible" :selectedRowKeys="selectedRowKeys"
              :disabled="true" />
          </div>
        </a-form-item>

        <Padding size="large" />
      </div>

      <div class="container">
        <div class="header">
          <img src="../../assets/contract-register/icon-file.png" alt="" />
          <span>附件</span>
        </div>

        <Padding size="large" />

        <a-form-item label="附件" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
          <div style="margin-top: 4px">
            <a-table bordered :dataSource="fileList" :pagination="false">
              <a-table-column title="附件名称">
                <template slot-scope="text">
                  <a :href="text.completePath" target="_blank">
                    {{ $getFileName(text.completePath) }}
                  </a>
                </template>
              </a-table-column>
            </a-table>
          </div>
        </a-form-item>

        <Padding size="large" />
      </div>

      <div class="center">
        <a-space>
          <a-button @click="$router.go(-1)">关闭</a-button>
        </a-space>
      </div>
    </a-form>

    <ExtraInfo />
  </div>
</template>

<script>
import area from "@/mixins/area";
import organization from "@/mixins/organization";
import watermark from "@/mixins/watermark";
import city from "@/mixins/city";

import { fetchDetail } from "@/api/subpackage-register";
import { mapGetters } from "vuex";

import ExtraInfo from "./components/extra-info";
import SubpackageList from "./components/subpackage-list";

export default {
  mixins: [area, organization, watermark, city],

  components: {
    SubpackageList,
    ExtraInfo,
  },

  data() {
    return {
      form: this.$form.createForm(this),
      selectedUser: {}, // 选择的经办人
      selectedContract: {}, // 选择的合同
      selectedCompany: {}, // 选择的分包方

      subpackageList: [], // 选择的合同下的分包列表
      addSubpackageVisible: false, // 新增分包弹窗
      selectedRowKeys: [], // 选中的分包项

      fileList: [], // 附件列表

      id: null, // 重新提交时的 ID
      detail: {}, // 重新提交时获取到的数据
      area: [],
      location: [],
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    sealTypeList() {
      return this.findDataDict("sealType");
    },
    isNecessaryTypeList() {
      return this.findDataDict("subType");
    },
    notNecessaryTypeList() {
      return this.findDataDict("notNeedSubType");
    },
    generalSubTypeList() {
      return this.findDataDict("generalSubType");
    },
    // 专业类型
    majorTypes() {
      return this.findDataDict("majorTypes");
    },
  },

  mounted() {
    const { query } = this.$route;
    const { id } = query || {};

    if (id && this.id !== id) {
      this.id = id;

      fetchDetail({ id }).then((res) => {
        console.log("detail", res);
        if (res) {
          this.detail = res;
          // 经办
          this.selectedUser = {
            userId: res.operatorId,
            name: res.operatorName,
          };

          this.selectedContract = {
            code: res.contractCode,
            name: res.contractName,
          };

          const area = [];
          if (res.areaName) {
            area.push(res.areaName);
          }
          if (res.subAreaName) {
            area.push(res.subAreaName);
          }
          if (res.l3AreaName) {
            area.push(res.l3AreaName);
          }
          this.area = area;
          this.selectedCompany = {
            id: res.companyId,
            name: res.companyName,
          };

          // 项目地点
          const location = [];
          if (res.belongProvince) {
            location.push(res.belongProvince);
          }
          if (res.belongCity) {
            location.push(res.belongCity);
          }
          if (res.district) {
            location.push(res.district);
          }
          this.location = location;

          this.form.getFieldDecorator("type", {
            initialValue: res.type,
          });
          if (res.major) {
            this.form.getFieldDecorator("major", {
              initialValue: res.major,
            });
          }

          if (Array.isArray(res.subPackagedList)) {
            const newList = res.subPackagedList.map((item, index) => {
              return {
                ...item,
                key: index,
              };
            });
            this.subpackageList = newList;
            this.selectedRowKeys = [
              newList.findIndex((item) => item.id === res.subPackaged.id),
            ];
          }

          this.fileList =
            typeof res.attachments === "string"
              ? res.attachments.split(",").map((item) => {
                  return {
                    completePath: item,
                  };
                })
              : [];
        }
      });

      return;
    } else {
      alert("URL 错误！");
    }
  },

  methods: {
    getType(type) {
      let list = [];
      if (this.detail.isneeded === 1) {
        list = this.isNecessaryTypeList;
      } else if (this.detail.isneeded === 0) {
        list = this.notNecessaryTypeList;
      } else if (this.detail.isneeded === 2) {
        list = this.generalSubTypeList;
      }
      const obj = list.find((item) => item.value === type);
      return obj ? obj.name : "";
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;

  .header {
    display: flex;
    align-items: center;
    padding: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid #f0f0f0;
    color: #1890ff;
    font-weight: bold;
    font-size: 14px;

    img {
      width: 14px;
      margin-right: 4px;
    }

    .extra {
      margin-left: auto;
    }
  }

  .tip {
    color: #999;
  }
}

.value {
  margin-top: 4px;
  border: 1px solid #d9d9d9;
  height: 30px;
  line-height: 30px;
  padding: 0 15px;
  box-sizing: content-box;
  border-radius: 2px;
}

.center {
  margin-top: 80px;
  margin-bottom: 80px;
}
</style>
